import React from "react"

import Welcome from "../components/welcome"
import MosaicSection from "../components/mosaic-section"
import GridShow from "../components/grid-show"
import ShowCase from "../components/showcase"
import Video from "../components/video-home"

const availableComponents = {
  welcome: Welcome,
  mosaic_section: MosaicSection,
  showcase: ShowCase,
  gridshow: GridShow,
  video: Video
}

const makeComponent = (type, props) => {
  const Component = availableComponents[type]
  return <Component block={props.block} {...props.data} />
}

export { makeComponent }
