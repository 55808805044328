import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import md from "markdown-it"
import { createMarkup, localize } from "../utils"
import GridItem from "./grid-item"

const converter = md()

const GridShow = props => {
  const { intl, block, productLines } = props
  return (
    <section class="c-grid-show a-section">
      <div class="a-container">
        <div class="a-section__content">
          <h3
            class="a-section__title a-section__title--main a-section__title--decoration"
            dangerouslySetInnerHTML={createMarkup(converter.renderInline(block.title))}
          />
          <div class="c-grid-show__grid position-relative">
            <div class="a-dots a-dots--large top-left"></div>
            {productLines.map(productLineData => {
              const productLine = localize(productLineData, intl.locale)
              return (
                <GridItem
                  title={productLine.name}
                  body={productLine.description}
                  slug={productLine.slug}
                  image={productLine.image}
                ></GridItem>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default injectIntl(GridShow)
