import React, { useEffect, useState } from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { createMarkup, localize } from "../utils"
import Image from "./image"

import { Swiper, SwiperSlide } from "swiper/react"

import { Navigation, Autoplay } from "swiper"

import "swiper/css"
import "swiper/css/navigation"
import { UilAngleLeft, UilAngleRight } from "@iconscout/react-unicons"
import { orderBy } from "lodash"

import productFormatter from "../utils/magento/productFormatter"

const Welcome = props => {
  const { products, slides, intl } = props
  const [productPrices, setProductPrices] = useState({})

  const slider = orderBy(slides, "ordine")

  return (
    <>
      <section className="c-welcome a-section">
        <Swiper
          slidesPerView={1}
          style={{ width: "100%" }}
          modules={[Navigation, Autoplay]}
          navigation={{
            prevEl: ".c-welcome__slider__prev",
            nextEl: ".c-welcome__slider__next",
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              autoplay: {
                delay: 2500,
                disableOnInteraction: true,
              },
            },
            1200: {
              autoplay: false,
            },
          }}
        >
          <div className="c-welcome__slider__navigation">
            <div className="c-welcome__slider__arrow c-welcome__slider__prev">
              <UilAngleLeft color={"#fff"} size={40} />
            </div>
            <div className="c-welcome__slider__arrow c-welcome__slider__next">
              <UilAngleRight color={"#fff"} size={40} />
            </div>
          </div>
          {slider.map(slide => {
            return (
              <SwiperSlide>
                <div className="c-welcome__slider__inner">
                  <div className="a-container c-welcome__container">
                    <div className="c-welcome__inner">
                      <h1
                        className="a-section__title is-alt a-section__title--main a-section__title--decoration"
                        dangerouslySetInnerHTML={createMarkup(slide.titolo)}
                      />
                      <p
                        className="a-section__paragraph  c-alt"
                        dangerouslySetInnerHTML={createMarkup(
                          slide.descrizione
                        )}
                      />
                      {slide.link_bottone && (
                        <button
                          className="a-btn is-alt a-btn--primary"
                          onClick={() => navigate(slide.link_bottone)}
                        >
                          {slide.testo_bottone || "Clicca qui"}
                        </button>
                      )}
                    </div>
                  </div>

                  <div class="c-welcome__image">
                    <Image image={slide.immagine} />
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </section>

      {products.length > 0 && (
        <div class="a-container a-container--fluid c-welcome__products-container">
          <h1 className="a-section__title a-section__title--decoration">
            {intl.formatMessage({ id: "terms.dashop" })}
          </h1>
          <div class="c-welcome__products">
            <div class="c-welcome__products__slider-wrapper">
              <Swiper
                className="c-welcome__products__slider"
                modules={[Navigation]}
                navigation={{
                  prevEl: ".c-welcome__products__slider__prev",
                  nextEl: ".c-welcome__products__slider__next",
                }}
                spaceBetween={50}
                slidesPerView={6}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 6,
                    spaceBetween: 40,
                  },
                }}
              >
                {products.map(product => (
                  <SwiperSlide>
                    <div className="c-welcome__products__wrapper">
                      <div
                        key={product.item_id}
                        class="c-welcome__products__item"
                        onClick={() => navigate(`/prodotto/${product.item_id}`)}
                      >
                        <Image
                          image={product.image}
                          className="c-welcome__products__item-image"
                        />
                      </div>
                      <span className="c-welcome__products__item-name">
                        {product.name}
                      </span>
                      <span className="c-welcome__products__item-price">
                        {productPrices[product.item_id]}
                      </span>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="c-welcome__products__slider__navigation">
                <div className="c-welcome__products__slider__arrow c-welcome__products__slider__prev">
                  <UilAngleLeft size={40} />
                </div>
                <div className="c-welcome__products__slider__arrow c-welcome__products__slider__next">
                  <UilAngleRight size={40} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default injectIntl(Welcome)
