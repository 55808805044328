import React from "react"

const SectionVideo =  props => {
  const { body } = props.block
  if (body.includes("youtube")) {
    const video = body.replace('youtube.com', 'youtube-nocookie.com')
    return (
      <section class="c-mosaic-section a-section">
        <div class="a-container">
          <div className="c-video">
            <iframe
              title="video"
              width="100%"
              height="100%"
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    )
  } else return null
}

export default SectionVideo
