import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { chunk } from "lodash"
import { createMarkup } from "../utils"
import Image from "../components/image"

const MosaicSection = props => {
  const { title, body, images } = props.block
  const imageChunks = chunk(images, 2)
  return (
    <section class="c-mosaic-section a-section">
      <div class="a-container">
        <div class="c-mosaic-section__image__wrapper position-relative">
          <div class="a-dots top-left a-dots--large"></div>
          {imageChunks.map(column => {
            return (
              <div>
                {column.map(img => (
                  <div class="a-section__image">
                    <Image image={img}/>
                  </div>
                ))}
              </div>
            )
          })}
        </div>
        <div class="a-section__text">
          <h3
            class="a-section__title a-section__title--main a-section__title--decoration"
            dangerouslySetInnerHTML={createMarkup(title)}
          />
          <p
            class="a-section__paragraph"
            dangerouslySetInnerHTML={createMarkup(body)}
          />
        </div>
      </div>
    </section>
  )
}

export default injectIntl(MosaicSection)
