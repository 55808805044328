import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import IconButton from "./icon-button"
import Image from "./image"

const GridItem = props => {
  const { title, body, image, slug, intl } = props

  return (
    <div class="c-grid-show__item">
      <div class="c-grid-item__image">
        <Image image={image} />
      </div>
      <div class="c-grid-item__content">
        <span class="c-grid-item__tag">
          {intl.formatMessage({ id: "terms.linea" })}
        </span>
        <h4 class="c-grid-item__title">{title}</h4>
        <p class="c-grid-item__text">{body}</p>

        <IconButton icon="UilArrowRight" size="26px" class="a-btn--square" slug={slug} onClick={() => navigate(`/prodotti/${slug}`)} />
      </div>
    </div>
  )
}

export default injectIntl(GridItem)
