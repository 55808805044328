import React, { createRef } from "react"
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
import { UilAngleLeft, UilAngleRight } from "@iconscout/react-unicons"
import Image from "./image"

function itemWidth(padding) {
  return document.querySelectorAll(".c-showcase__item")[0].offsetWidth + padding
}

const SlideShow = props => {
  const { block, editorials } = props
  const slider = createRef()

  const scroll = movement => {
    slider.current.scrollBy({
      behavior: "smooth",
      ...movement,
    })
  }
  return (
    <>
      <section class="c-showcase">
        <div class="c-showcase__container a-container">
          <div class="c-showcase__inner">
            <div class="c-showcase__header">
              <h4 class="a-section__title a-section__title--alt a-section__title--decoration">
                {block.title}
              </h4>
              <div class="c-showcase__text">
                <p>{block.body}</p>
                <button
                  class="a-btn a-btn--primaryAlt"
                  onClick={() => navigate(block.button_link)}
                >
                  {block.button_text}
                </button>
              </div>
            </div>
            <div class="c-showcase__slider__inner">
              <div class="c-showcase__slider" ref={slider}>
                {editorials.map(editorial => (
                  <Link to={`/editoriali/${editorial.slug}`}>
                    <div className="c-showcase__item">
                      <Image
                        image={editorial.image_cover}
                        className="c-showcase__item-image"
                      />
                      <div className="c-showcase__item-title">
                        {editorial.title}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="c-showcase__slider-controller">
        <UilAngleLeft
          size="30"
          onClick={() => scroll({ left: -itemWidth(20) })}
        />
        <UilAngleRight
          size="30"
          onClick={() => scroll({ left: itemWidth(20) })}
        />
      </div>
    </>
  )
}

export default injectIntl(SlideShow)
